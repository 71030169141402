import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http"
import { Injectable } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { Router } from "@angular/router"
import { EMPTY, Observable, catchError } from "rxjs"
import { Dialog403Component } from "src/app/features/feature/components/dialog403/dialog403.component"
import { ErrorsComponent } from "src/app/features/feature/components/errors/errors.component"
import { AuthService } from "../services/auth.service"
import { CommonService } from "../services/commonservice.service"

@Injectable()
export class WebWorkerInterceptor implements HttpInterceptor {
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  currentUrl: any
  private isErrorDialogOpen = false

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    public commonService: CommonService,
  ) {
    // Subscribe to dialog state changes
    this.dialog.afterAllClosed.subscribe(() => {
      this.isErrorDialogOpen = false
    })
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getToken()

    if (authToken) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + authToken),
      })
    } else {
      console.error("No token provider", request.url)
    }

    let config = new MatSnackBarConfig()
    config.panelClass = [
      "my-custom-snackbar-error",
      "mat-toolbar",
      "mat-primary",
    ]
    config.verticalPosition = this.verticalPosition
    config.horizontalPosition = this.horizontalPosition
    config.duration = 10000

    return next.handle(request).pipe(
      catchError((err) => {
        if (err) {
          switch (err.status) {
            case 502:
              this.snackBar.open(err.message, "Close", config)
              break
            case 504:
              this.dialog.closeAll()
              if (this.currentUrl.url === "/job-result") {
                this.snackBar.open(err.status, "Close", config)
              } else {
                this.router.navigate(["/geteway"])
              }
              break
            case 400:
            case 404:
            case 500:
              if (!this.isErrorDialogOpen) {
                this.isErrorDialogOpen = true
                let displayMessage = "Error occurred"

                if (err.error) {
                  displayMessage =
                    err.error ||
                    err.error.error ||
                    err.error.message ||
                    err.message ||
                    "Internal Server Error"
                }

                const dialogRef = this.dialog.open(ErrorsComponent, {
                  width: "360px",
                  height: "auto",
                  hasBackdrop: true,
                  panelClass: ["error500", "error-dialog-overlay"],
                  data: {
                    status: err.status,
                    statusText: err.statusText,
                    message: displayMessage,
                  },
                })

                if (this.commonService.currentStep !== 1) {
                  dialogRef.backdropClick().subscribe(() => {
                    this.commonService.clicked = !this.commonService.clicked
                  })
                }
              }
              return EMPTY
            case 403:
              if (!this.isErrorDialogOpen) {
                this.isErrorDialogOpen = true
                this.currentUrl = this.router.routerState.snapshot
                if (this.currentUrl.url === "/metadata") {
                  this.snackBar.open(err.status, "Close", config)
                } else {
                  this.dialog.closeAll()
                  this.dialog.open(Dialog403Component, {
                    width: "360px",
                    height: "auto",
                    hasBackdrop: true,
                    panelClass: ["error403", "error-dialog-overlay"],
                    data: {
                      status: err.status,
                      statusText: err.statusText,
                      message: err.body?.message,
                      ok: err.ok,
                    },
                  })
                }
              }
              break
            case 401:
              this.authService.onLogout()
              break
            case 200:
              console.log("200", request)
              next.handle(request)
          }
        }
        // return throwError(() => err);
        if (authToken) {
          // Clone the request and add the authentication token to the headers
          const authReq = request.clone({
            headers: request.headers.set(
              "Authorization",
              `Bearer ${authToken}`,
            ),
          })
          console.log(authReq)
          return next.handle(authReq)
        } else {
          // If there is no token, proceed with the original request
          return next.handle(request)
        }
      }),
    )
  }
}
